









































































































































































































import {
  computed,
  defineComponent,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import Support from '../almarwan/components/Support.vue';
import type { MetaInfo } from 'vue-meta';
import { useUiHelpers } from '../composables';
import CustomBreadcrumbs from '../components/custom/CBreadcrumbs.vue'

export default defineComponent({
  name: 'ContactUs',
  components: {
    Support,
    CustomBreadcrumbs
  },
  data() {
    return {
      breadcrumbs: [{ text: 'Contact', link: '/contact-us' }],
    };
  },
  setup() {
    const choiceAll = ref(true);
    const choiceUae = ref(true);
    const choiceOman = ref(true);
    const choiceKsa = ref(true);
    const selectedFilter = ref(null);
    const selectedFilterArr = ref([]);
    const {
      app: { i18n },
    } = useContext();
    const { getFullUrl } = useUiHelpers();
    const addresses = ref([
      {
        name: i18n.t('Al Marwan Head Office Showroom'),
        headaddress: i18n.t('Address'),
        address: i18n.t(
          'Sheikh Mohammed Bin Zayed Rd, Industrial Area 15, Sharjah'
        ),
        link: 'https://goo.gl/maps/8geuKWk32SamfpJRA',
        hourText: i18n.t('Working Hours'),
        openHours: i18n.t(
          'Mon to Fri 8:00am to 6:30pm Saturday - Closed <br/> Sunday - Closed'
        ),
        filter: 'uae',
      },
      {
        name: i18n.t('Al Marwan Industrial Area 2 Showroom'),
        headaddress: i18n.t('Address'),
        address: i18n.t('Second Industrial St - Industrial Area 2, Sharjah'),
        link: 'https://maps.app.goo.gl/ZonSJ8vabyaMhypM7',
        hourText: i18n.t('Working Hours'),
        openHours: i18n.t(
          'Mon to Fri 8:00am to 6:30pm Saturday - Closed <br/> Sunday - Closed'
        ),
        filter: 'uae',
      },
      {
        name: i18n.t('Al Marwan Jebel Ali Showroom'),
        headaddress: i18n.t('Address'),
        address: i18n.t('Jafza - Mina Jebel Ali - Jebel Ali Freezone, Dubai'),
        link: 'https://maps.app.goo.gl/t7JDsDkjV7JeH6Fe9',
        hourText: i18n.t('Working Hours'),
        openHours: i18n.t(
          'Mon to Fri 8:00am to 6:30pm Saturday - Closed <br/> Sunday - Closed'
        ),
        filter: 'uae',
      },
      {
        name: i18n.t('Al Marwan Oman Showroom'),
        headaddress: i18n.t('Address'),
        address: i18n.t(
          'Way No 6049, Building No 3760, Ghala industrial Area, Muscat'
        ),
        link: 'https://maps.app.goo.gl/6qsE2Zt4JxBbKvKt6',
        hourText: i18n.t('Working Hours'),
        openHours: i18n.t(
          'Sun - Thu 8:00am to 5:00pm Friday - Closed <br/> Saturday - Closed'
        ),
        filter: 'oman',
      },
    ]);
    const officeAddresses = ref([
      {
        name: i18n.t('Al Marwan Abu Dhabi Office'),
        headaddress: i18n.t('Address'),
        address: i18n.t(
          'Office no 28 - Block A, Hamdan st - Musaffah - Musaffah Industrial - Abu Dhabi'
        ),
        link: 'https://maps.app.goo.gl/bFbZc4fidiY6DM6T8',
        hourText: i18n.t('Working Hours'),
        openHours: i18n.t(
          'Mon to Fri 8:00am to 6:30pm Saturday - Closed <br/> Sunday - Closed'
        ),
        filter: 'uae',
      },
      {
        name: i18n.t('Al Marwan Saudi Arabia Office'),
        headaddress: i18n.t('Address'),
        address: i18n.t(
          'Inside Localizer Mall, 2803 Prince Muhammad Ibn Abd Al Aziz, Al Olaya, Riyadh'
        ),
        link: 'https://maps.app.goo.gl/YjzsXS4hWJxpQgt78',
        hourText: i18n.t('Working Hours'),
        openHours: i18n.t(
          'Sun - Thu 8:00am to 5:00pm Friday - Closed <br/> Saturday - Closed'
        ),
        filter: 'ksa',
      },
    ]);
    const isFilterSelected = (filterVal) => {
      return selectedFilterArr.value &&
        selectedFilterArr.value.includes(filterVal)
        ? true
        : false;
    };
    const stores = computed(() => {
      return addresses.value.filter(
        (item) =>
          isFilterSelected(item.filter) || selectedFilterArr.value.length === 0
      );
    });
    const offices = computed(() => {
      return officeAddresses.value.filter(
        (item) =>
          isFilterSelected(item.filter) || selectedFilterArr.value.length === 0
      );
    });
    const addToFilter = (item) => {
      if (selectedFilterArr.value && selectedFilterArr.value.includes(item)) {
        const index = selectedFilterArr.value.indexOf(item);
        if (index > -1 && selectedFilterArr.value) {
          selectedFilterArr.value?.splice(index, 1);
        }
        if (selectedFilterArr.value && selectedFilterArr.value.length === 0) {
          choiceAll.value = true;
        }
      } else {
        selectedFilterArr.value = [];
        selectedFilterArr.value.push(item);
        choiceAll.value = false;
      }
    };

    return {
      choiceAll,
      choiceUae,
      choiceOman,
      choiceKsa,
      selectedFilter,
      selectedFilterArr,
      addToFilter,
      isFilterSelected,
      stores,
      offices,
      addresses,
      getFullUrl,
    };
  },
  head(): MetaInfo {
    const title = this.$t('contactUsTitle').toString();
    const description = this.$t(
      'Contact Al Marwan Heavy Machinery for your heavy equipment rental and sale needs. Find heavy equipment workshops near you, visit our stores in UAE.'
    ).toString();
    const baseurl =
      this.$i18n.locale !== 'ar'
        ? 'https://almarwan.com'
        : 'https://almarwan.com/ar';
    return {
      title,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: title,
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: title,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: title,
        },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description,
        },
        {
          hid: 'og:apple-mobile-web-app-title',
          name: 'og:apple-mobile-web-app-title',
          content: title,
        },
        {
          hid: 'site_name',
          name: 'site_name',
          content: title,
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: title,
        },
        {
          hid: 'twitter:text:title',
          name: 'twitter:text:title',
          content: title
        },
        {
          hid: 'url',
          name: 'url',
          content: this.getFullUrl(this.$route.path),
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: this.getFullUrl(this.$route.path),
        },
        {
          property: 'og:image',
          content: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
        },
        {
          property: 'twitter:image',
          content: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: process.env.BASE_URL.replace(/\/$/, '') + this.localePath('/contact-us'),
        },
      ],
      script: [
        {
          type: 'application/ld+json',
          class: 'saswp-schema-markup-output',
          json: {
            '@context': 'https://schema.org',
            '@type': 'Corporation',
            '@id': `${baseurl}/contact-us'#Organization`,
            name: 'Al Marwan Heavy Machinery',
            description: description,
            url: `${baseurl}/contact-us`,
            logo: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
            contactPoint: [
              {
                '@type': 'ContactPoint',
                telephone: '+(971) 56 543 3222',
                contactType: 'sales',
                areaServed: 'AE',
                availableLanguage: ['en', 'Arabic'],
              },
              {
                '@type': 'ContactPoint',
                telephone: '+966 55 510 7867',
                contactType: 'sales',
                areaServed: 'SA',
                availableLanguage: ['en', 'Arabic'],
              },
              {
                '@type': 'ContactPoint',
                telephone: '+968 9117 1061',
                contactType: 'sales',
                areaServed: 'OM',
                availableLanguage: ['en', 'Arabic'],
              },
            ],
            sameAs: [
              'https://www.facebook.com/almarwan.heavy.machinery/',
              'https://twitter.com/almarwanheavy/',
              'https://www.pinterest.com/almarwanheavyequipment/',
              'https://www.instagram.com/almarwan.machinery',
              'https://www.linkedin.com/company/almarwan-heavy-machinery/',
              'https://www.youtube.com/@almarwan',
            ],
          },
        },
        {
          type: 'application/ld+json',
          class: 'saswp-schema-markup-output',
          json: {
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            '@id': `${baseurl}/contact-us'/#breadcrumb`,
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': this.getFullUrl(this.$route.path),
                  name: 'Home',
                },
              },
              {
                '@type': 'ListItem',
                position: 2,
                item: {
                  '@id': `${baseurl}/contact-us`,
                  name: 'Contact Us',
                },
              },
            ],
          },
        },
      ],
    };
  },
  methods: {
    choiceAllCountry() {
      this.selectedFilterArr = [];
      this.choiceAll = true;
      this.selectedFilter = null;
    },
  },
});
