<template>
  <section class="support" :style="{'background-color': bgColor}">
    <div class="container">
      <div class="support-wrapper flex flex-col lg:flex-row gap-y-5 lg:gap-y-0 align-start">
        <Icon name="support" />
        <div>
          <p :style="{'color': textColor}">{{$t('Not sure what you need?')}}</p>
          <h5 :style="{'color': textColor}" v-if="!enableBlackBg">{{$t('Ask our experts')}}</h5>
          <p class="reach-out" v-html="translatedHtml(linkPath, linkText)"></p>
        </div>
      </div>

      <div>
        <!-- <h4>We Help Companies Better Utilize Heavy Equipment</h4> -->
        <h4 :style="{'color': textColor}">{{$t('We help companies better utilize heavy equipment')}}</h4>
      </div>
    </div>
  </section>
</template>

<script>
import Icon from '@/almarwan/components/Icon.vue'

export default {
  name: 'SupportComponent',
  components: {
    Icon
  },
  props: {
    bgColor: {
      type: String,
      default: '#B4E2E2'
    },
    textColor: {
      type: String,
      default: '#132528'
    },
    enableBlackBg: {
      type: Boolean,
      default: false
    },
    linkPath: {
      type: String,
      default: '/'
    },
    linkText: {
      type: String,
      default: ''
    }
  },
  methods: {
    translatedHtml(path, text) {
      let translatedString = this.$i18n.t(text).toString()
      translatedString = translatedString.replace('{linkStart}', `<a class="text-color-orange" href="${path}">`)
        .replace('{linkText}', text)
        .replace('{linkEnd}', '</a>');
      return translatedString;
    }
  }
}
</script>
